import React, {useState, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import Typography from '@material-ui/core/Typography';
import {Select, MenuItem, InputLabel, Card, FormControl,
  Slider, CircularProgress, CardContent,
  Grid} from '@material-ui/core';
import clientConfig from '../../Config/clients.json';
import {DateRangePickerComponent} from '../../Utils/DatePickers';
import {ApiClient} from '../../Utils/ApiClient';
import {CenterWrapper} from '../StyledComponents/BasicStyles';
import {getDatePlusInterval} from '../../Utils/DateUtils';
import {useContext} from 'react';
import {UserContext} from '../../contexts/userContext';
import {RegularSpacer} from '../BasicComponents/Spacers';

const useStyles = createUseStyles(
    {
      onlyMarginBottom: {
        marginBottom: 10,
      },
    },
);

const systemMarks = [
  {value: 0, label: '0'},
  {value: 2.5, label: ''},
  {value: 5, label: ''},
  {value: 7.5, label: ''},
  {value: 10, label: ''},
  {value: 12.5, label: ''},
  {value: 15, label: ''},
  {value: 17.5, label: ''},
  {value: 20, label: ''},
  {value: 22.5, label: ''},
  {value: 25, label: ''},
  {value: 27.5, label: ''},
  {value: 30, label: '30'},
  {value: 32.5, label: ''},
  {value: 35, label: ''},
  {value: 37.5, label: ''},
  {value: 40, label: ''},
  {value: 42.5, label: ''},
  {value: 45, label: ''},
  {value: 47.5, label: ''},
  {value: 50, label: ''},
  {value: 52.5, label: ''},
  {value: 55, label: ''},
  {value: 57.5, label: ''},
  {value: 60, label: '60'},
];


function IPMRunsPage() {
  const classes = useStyles();
  const {currentClient, setCurrentClient} = useContext(UserContext);
  const [runs, setRuns] = useState([]);
  const [speed, setSpeed] = useState(30);
  const [startDate, setStartDate] = useState(() => getDatePlusInterval(-31).toISOString());
  const [endDate, setEndDate] = useState(() => getDatePlusInterval(0, true).toISOString());
  const [inProgress, setInProgress] = useState(false);
  const clients = Object.keys(clientConfig.names).map(function(key) {
    return clientConfig.names[key];
  });

  useEffect(() => {
    if (!currentClient) {
      return;
    }
    getRuns();
  }, [startDate, endDate, currentClient, speed]);

  useEffect(() => {
    console.log(runs);
  }, [runs]);

  const getRuns = async () => {
    setInProgress(true);
    const data = await ApiClient.getSlowRuns(currentClient, startDate, endDate, speed);
    if (!data) {
      setInProgress(false);
      return;
    }
    setRuns(groupRuns(uniqueRuns(data)));
    setInProgress(false);
  };

  const handleChangeDateStart = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(0, 0, 0, 0);
    setStartDate(dateObject.toISOString());
  };

  const handleChangeDateEnd = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(23, 59, 59, 999);
    setEndDate(dateObject.toISOString());
  };


  const uniqueRuns = (runs) => {
    // runs are a list of objects with values collection_date and data.lane
    // we want to have only unique collection_date and data.lane combinations
    // we can use a set to store the unique values
    const uniqueRuns = new Set();
    runs.forEach((run) => {
      // day format yyyy-mm-dd
      const date = new Date(run.collection_date);
      const day = `${date.getUTCFullYear()}-${('0' +
                (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`;
      uniqueRuns.add(day + '_' + run.data.lane);
    });
    // convert set to list
    return Array.from(uniqueRuns);
  };

  const groupRuns = (runs) => {
    // a run object is date underscore lane
    // we want to group the runs by date
    const groupedRuns = {};
    runs.forEach((run) => {
      const parts = run.split('_');
      if (groupedRuns[parts[0]]) {
        groupedRuns[parts[0]].push(Number(parts[1]));
      } else {
        groupedRuns[parts[0]] = [Number(parts[1])];
      }
    });
    Object.keys(groupedRuns).forEach((key) => {
      groupedRuns[key].sort((a, b) => a - b);
    });
    return groupedRuns;
  };

  return (
    <CenterWrapper width={1200} >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth className={classes.onlyMarginBottom}>
                <InputLabel id="client-label">Client</InputLabel>
                <Select
                  labelId="client-picker"
                  id
                  value={currentClient}
                  label="Client"
                  onChange={(event) => setCurrentClient(event.target.value)}>
                  {clients.map((client) => <MenuItem key={client + '-client-picker'}
                    id={client} value={client}>{client}</MenuItem>)}
                </Select>
              </FormControl>
              <RegularSpacer size={2} />
              <FormControl fullWidth className={classes.onlyMarginBottom}>
                <DateRangePickerComponent
                  id="dateFrom"
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  changeStartDate={(event) => handleChangeDateStart(event)}
                  changeEndDate={(event) => handleChangeDateEnd(event)}
                />
              </FormControl>
              <FormControl fullWidth className={classes.onlyMarginBottom}>
                <InputLabel id="system-speed-select" className={classes.inputLabel}>
                Max. System Speed (m/min)
                </InputLabel>
                <RegularSpacer />
                <Slider aria-label="System-Speed" min={0.0} max={60.0} step={0.05}
                  style={{width: '95%', marginTop: '20px'}}
                  value={speed} onChangeCommitted={(_, newValue) => setSpeed(newValue)}
                  marks={systemMarks} valueLabelDisplay="auto" />
              </FormControl>
              {/* {jobErrorMessage ? <Typography>No matching snapshots</Typography> : ''} */}
            </CardContent>
          </Card >
        </Grid>
        <Grid item xs={12}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Typography variant="h4">Slow runs:</Typography>
            <RegularSpacer size={2} />
            {inProgress ? <CircularProgress /> : ''}
          </div>
        </Grid>
        {runs ? Object.keys(runs).map((key) => (
          <Grid item xs={12} key={key}>
            <Typography variant="h6">{key}</Typography>
            <Card>
              <CardContent>
                {
                  runs[key].map((lane) => (
                    <Typography key={lane} variant="body1">Lane {lane}</Typography>
                  ))
                }
              </CardContent>
            </Card>
          </Grid>
        )) : ''}
      </Grid>
    </CenterWrapper>
  );
}

export default IPMRunsPage;
