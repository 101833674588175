import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import 'date-fns';
import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {ApiClient} from '../../Utils/ApiClient';
import {UserContext} from '../../contexts/userContext';
import {makeStyles} from '@material-ui/styles';
import SnapshotFilter from './SnapshotFilter';
import SnapshotListItem from './SnapshotListItem';
import {getDatePlusInterval} from '../../Utils/DateUtils';
import {getUniqueLocations} from '../../Utils/LocationUtils';
import SimpleFilter from './SimpleFilter';
import {Typography, Slider} from '@material-ui/core';
import LanePositionPicker from '../UserInput/LanePositionPicker';
import InBedPosition from '../../Utils/InBedPosition';
import {PrimaryColorText} from './StyledComponents/BasicComponents';
import {CartContext} from '../ShoppingCart/CartContext';
import {useContext} from 'react';
import {ConstructionAddIcon, ConstructionRemoveIcon} from '../ShoppingCart/CartIcons';
import {DatePickerComponent, DateRangePickerComponent} from '../../Utils/DatePickers';
import FilterByCrop from './FilterByCrop';
import FilterByLocationId from './FilterByLocationId';
import SnapshotSortByDate from './SnapshotSortByDate';
import Checkbox from '@mui/material/Checkbox';
const useStyles = makeStyles((theme) => ({
  listCard: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 500,
    marginTop: theme.spacing(2),
  },

  smallMarginRight: {
    marginRight: theme.spacing(1),
  },

  locationSelection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  positionCard: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 200,
  },
  list: {
    padding: theme.spacing(0),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  container: {
    padding: 0,
  },
  buttonGroup: {
    marginBottom: theme.spacing(2),
  },
  datePickerCard: {
    marginBottom: theme.spacing(2),
  },
  listButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));

function SnapshotList(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  const defaultSelectedButton = 'Good';
  const componentRefs = useRef([]);
  const {currentClient, getUserConfig} = useContext(UserContext);
  const {addToCartById, removeFromCart, areAlreadyInCart, isCartVisible} = useContext(CartContext);

  const [data, setData] = useState(props.data);
  const [filteredData, setFilteredData] = useState([]);
  const [unsortedData, setUnsortedData] = useState([]);
  const [filterBatchId, setFilterBatchId] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [highlightDates, setHighlightDates] = useState(new Set());
  const [snapshotFilter, setSnapshotFilter] = useState(new SnapshotFilter());
  const [snapshotLocations, setSnapshotLocations] = useState(null);
  const [inBedPosition, setInBedPosition] = useState(new InBedPosition('', '', null, null));
  const [defaultInBedPosition, setDefaultInBedPosition] = useState(null);
  const [buttonGroup, setButtonGroup] = useState(<span></span>);
  const [selectedButton, setSelectedButton] = useState(defaultSelectedButton);
  const [inputsAreSet, setInputsAreSet] = useState(false);
  const [sortSnapshotsBy, setSortSnapshotsBy] = useState('distance');
  const [snapshotRuns, setSnapshotRuns] = useState([]);
  const [selectedRun, setSelectedRun] = useState(snapshotRuns.length === 0 ? 0 : snapshotRuns.length - 1);
  const [showPlantCounts, setShowPlantCounts] = useState(false);
  const [plantConfidence, setPlantConfidence] = useState(0.5);

  useEffect(() => {
    setSelectedStartDate(getDatePlusInterval());
    loadURLParameters();
  }, []);

  useEffect(() => {
    if (sortSnapshotsBy === 'collection_date') {
      setFilteredData(snapshotRuns[selectedRun] || []);
    }
  }, [selectedRun, snapshotRuns]);

  useEffect(() => {
    if (currentClient === null | selectedStartDate === null | selectedEndDate === null) {
      return;
    }
    setInputsAreSet(true);
  }, [currentClient, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (props.snapshotId) {
      navigateToOneSnapshot(props.snapshotId);
      props.setSnapshotId('');
    }
  }, [props.snapshotId]);

  useEffect(() => {
    setSelectedRun(snapshotRuns.length === 0 ? 0 : snapshotRuns.length - 1);
  }, [sortSnapshotsBy]);

  useEffect(() => {
    setSnapshotLocations(null);
    setData([]);
    // If there is no range, set the end date to the end of the selected day
    let finalEndDate = new Date(selectedEndDate);
    if (!getUserConfig(true)?.date_range) {
      finalEndDate = new Date(selectedStartDate);
      finalEndDate.setHours(23, 59, 59, 0);
    }
    props.updateData(selectedStartDate, finalEndDate);
    if (inputsAreSet) {
      resetFilters();
    }
  }, [currentClient, selectedStartDate, selectedEndDate, inputsAreSet]);

  useEffect(() => {
    if (currentClient === null | currentClient === undefined) {
      return;
    }
    loadButtonGroup();
    markDaysWithUploads();
  }, [currentClient, selectedButton]);


  useEffect(() => {
    if (props.index && props.index != -1 && props.data !== '') {
      setCurrentIndex(props.index);
      setSelectedItem(filteredData[props.index]._id);
    }
  }, [props.index]);

  useEffect(() => {
    if (selectedItem && filteredData.length > 0) {
      scrollToItem(currentIndex);
    }
  }, [selectedItem, filteredData]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    const newFilter = new SnapshotFilter();
    newFilter.filterByButton = selectedButton;
    newFilter.lane = inBedPosition.lane;
    newFilter.subLane = inBedPosition.subLane;
    newFilter.batch = filterBatchId;
    setSnapshotFilter(newFilter);
  }, [selectedButton, inBedPosition, filterBatchId]);

  useEffect(() => {
    filterData();
  }, [snapshotFilter, data]);

  useEffect(() => {
    setSortSnapshotsBy('distance');
    setSnapshotRuns([]);
    setSelectedRun(0);
  }, [selectedStartDate, selectedEndDate]);

  const loadURLParameters = () => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    let date = null;
    if (queryParams.has('date')) {
      date = new Date(queryParams.get('date'));
    }
    const lane = queryParams.get('lane');
    const subLane = queryParams.get('subLane');
    const snapshotId = queryParams.get('snapshotId');
    if (date === null && snapshotId === null) {
      return;
    }
    if (date !== null) {
      setSelections(date, null, lane, subLane);
      return;
    }
    if (snapshotId !== null) {
      navigateToOneSnapshot(snapshotId);
      return;
    }
  };

  const navigateToOneSnapshot = (snapshotId) => {
    ApiClient.findSnapshotById(snapshotId).then((snapshot) => {
      if (snapshot === null) {
        return;
      }
      setStartingDate(new Date(snapshot.collection_date));
      const foundLane = snapshot?.data?.lane ?? null;
      const foundSubLane = snapshot?.data?.sub_lane ?? null;
      const date = new Date(snapshot.collection_date);
      const endDate = new Date(snapshot.collection_date);
      endDate.setHours(23, 59, 59, 0);
      setSelections(date, endDate, snapshot._id, foundLane, foundSubLane);
    });
  };

  const setSelections = (startDate, endDate, snapshotId = null, lane = '', subLane = '') => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    setSelectedButton('All');
    if (snapshotId !== null) {
      setSelectedItem(snapshotId);
    }
    if (lane !== null || subLane !== null) {
      const inBedPosition = new InBedPosition(lane, subLane, null);
      setDefaultInBedPosition(inBedPosition);
    }
  };

  const resetFilters = (buttonValue = null) => {
    setInBedPosition(new InBedPosition('', '', null, null));
    setDefaultInBedPosition(new InBedPosition('', '', null, null));
    if (buttonValue !== null) {
      setSelectedButton(buttonValue);
    }
  };

  const filterData = () => {
    const visibleDataBeforeLocationFilter = snapshotFilter.filter(data, true);
    setSnapshotLocations(getUniqueLocations(visibleDataBeforeLocationFilter));
    let visibleData = snapshotFilter.filter(data);
    visibleData = indexData(visibleData);
    setFilteredData(visibleData);
    setUnsortedData(visibleData);
  };

  const scrollToItem = (index) => {
    const componentRef = componentRefs.current[index];
    if (componentRef !== null && componentRef !== undefined && componentRef.current !== null) {
      if (document.contains(componentRef)) {
        componentRef.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
        return;
      }
    }
  };

  const indexData = (data) => {
    let selectedItemNotInTheList = true;
    // eslint-disable-next-line guard-for-in
    for (const i in data) {
      data[i].index = i;
      if (data[i]._id === selectedItem) {
        setCurrentIndex(i);
        selectedItemNotInTheList = false;
        props.changeIndex(data[i]);
      }
    }
    if (selectedItemNotInTheList) {
      setCurrentIndex(-1);
    }
    return data;
  };

  const handleKeyDown = (e, i, list) => {
    const indx = parseInt(i);
    if (e.keyCode === 37 && currentIndex > 0) {
      handleToggle(list[indx - 1]);
    } else if (e.keyCode === 39 && currentIndex < (list.length - 1)) {
      handleToggle(list[indx + 1]);
    }
  };

  const handleToggle = (item) => {
    if (currentIndex !== item.index || currentIndex == -1) {
      setCurrentIndex(item.index);
      setSelectedItem(item._id);
      props.changeIndex(item);
    }
  };

  const handleChangeDateStart = (date) => {
    const dateObject = new Date(date);
    dateObject.setHours(0, 0, 0, 0);
    setSelectedStartDate(dateObject);
  };

  const handleChangeDateEnd = (date) => {
    const dateObject = new Date(date);
    dateObject.setHours(23, 59, 59, 0);
    setSelectedEndDate(dateObject);
  };

  const loadButtonGroup = async () => {
    const clientConfig = getUserConfig(true);
    setButtonGroup(
        <ToggleButtonGroup
          value={selectedButton}
          exclusive
          onChange={(e) => setSelectedButton(e.target.value)}
          aria-label="text alignment"
          fullWidth>
          {clientConfig.toggle_buttons.map((item) =>
            <ToggleButton key={item} value={item} id={item}>{t(item.toLowerCase())}</ToggleButton>,
          )}
        </ToggleButtonGroup>,
    );
  };

  const markDaysWithUploads = async () => {
    setHighlightDates(new Set());
    const status = selectedButton === 'Good' ? 'analyzed' :
            selectedButton === 'Failure' ? 'failed' : selectedButton === 'Discarded' ? 'discarded' : null;
    const onlyDiseased = selectedButton === 'Disease';
    ApiClient.findDaysWithUploads(currentClient, status, onlyDiseased).then((days) => {
      setHighlightDates(days);
    });
  };

  const showPositionPickers = () => {
    return snapshotLocations && getUserConfig(true)?.sort_by == 'mdc_listing' && props.showAnnotations === false;
  };

  // check if the current client is allowed to access snapshot sorting options
  const showSortBy = () => {
    return getUserConfig(true)?.sort_by !== 'batch';
  };

  const isFilteredDataVisible = () => {
    return !showPositionPickers() || !(inBedPosition.lane === '' || inBedPosition.subLane === '');
  };

  // takes in snapshot data for a particular day which is sorted by collection_date
  // groups snapshots by runs (run 1, run2, ..etc) if a snapshot from the same place was taken twice
  const groupSnapshotsByRun = (snapshotData) => {
    let run = [];
    const runs = [];
    let index = 0;
    for (let i = 0; i < snapshotData.length; i++) {
      snapshotData[i].index = index;
      index += 1;
      if (i < snapshotData.length - 1) {
        if (snapshotData[i+1].data.position_id.total_distance < snapshotData[i].data.position_id.total_distance) {
          run.push(snapshotData[i]);
          runs.push(run);
          run = [];
          index = 0;
        } else {
          run.push(snapshotData[i]);
        }
      } else {
        run.push(snapshotData[i]);
        runs.push(run);
        run = [];
        index = 0;
      }
    }
    setSnapshotRuns(runs);
  };

  // handles switching between sorting methods
  const handleSortBy = (event) => {
    const sortingMethod = event.target.value;
    setSortSnapshotsBy(sortingMethod);
    if (sortingMethod === 'collection_date') {
      // sort snapshots by collection_date
      const sortedData = unsortedData.toSorted((a, b) => new Date(a.collection_date) - new Date(b.collection_date));
      groupSnapshotsByRun(sortedData);
    } else {
      setFilteredData(indexData(unsortedData));
    }
  };

  // handles which data to display when switching between different runs in collection_date sorting
  const handleRunSelect = (event) => {
    setCurrentIndex(-1);
    const runNumber = event.target.value;
    const snapshotList = snapshotRuns[runNumber];
    setSelectedRun(runNumber);
    setFilteredData(snapshotList);
  };

  const getSnapshotList = () => {
    if (!isFilteredDataVisible()) {
      return <></>;
    }
    return (
      <Card className={classes.listCard}>
        <List className={classes.list} component="nav" aria-label="secondary mailbox folders">
          {filteredData.map((item, index) =>
            <SnapshotListItem
              innerRef={(ref) => (componentRefs.current[index] = ref)}
              key={'SnapshotListItem' + item._id}
              item={item}
              annotations={props.annotations}
              showAnnotations={props.showAnnotations}
              selected={(selectedItem === item._id)}
              onClick={() => handleToggle(item)}
              onKeyDown={(e) => handleKeyDown(e, currentIndex, data)}
              showPlantCounts={showPlantCounts}
              plantConfidence={plantConfidence}
            />,
          )}
        </List>
      </Card>
    );
  };

  const handleBatchSubmit = () => {
    resetFilters('All');
    props.searchBatch(filterBatchId);
  };

  const getShoppingCartButton = () => {
    if (!isFilteredDataVisible() || filteredData.length === 0) {
      return <></>;
    }
    const filteredDataCopy = [...filteredData];
    const ids = filteredDataCopy.map((snapshot) => snapshot._id);
    if (areAlreadyInCart(ids)) {
      return <div style={{display: 'flex', alignItems: 'center', userSelect: 'none'}}
        onClick={() => filteredDataCopy.forEach((snapshot) => {
          removeFromCart(snapshot._id);
        })}>
        <ConstructionRemoveIcon />
        <PrimaryColorText> {t('Remove All')}</PrimaryColorText>
      </div>;
    }
    return <div style={{display: 'flex', alignItems: 'center', userSelect: 'none'}}
      onClick={() => filteredDataCopy.forEach((snapshot) => {
        addToCartById(snapshot._id);
      })}>
      <ConstructionAddIcon />
      <PrimaryColorText> {t('Add all')}</PrimaryColorText>
    </div>;
  };

  const getConfidenceSlider = () => {
    return <Slider aria-label="System-Speed" min={0.0} max={1} step={0.01} style={{width: '95%'}}
      value={plantConfidence} onChangeCommitted={(_, newValue) => setPlantConfidence(newValue)}
      valueLabelDisplay="auto" valueLabelFormat={`${parseInt(plantConfidence*100)}%`}/>;
  };

  const getPlantCountCheckbox = () => {
    const diseaseButtonExists = getUserConfig(true)?.toggle_buttons.includes('Disease');
    if (!isFilteredDataVisible() || filteredData.length === 0 || !diseaseButtonExists) {
      return <></>;
    }
    return <div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Checkbox
          color='success'
          checked={showPlantCounts}
          onChange={() => setShowPlantCounts(!showPlantCounts)}
          inputProps={{'aria-label': 'controlled'}}
        />
        <PrimaryColorText>{showPlantCounts ? 'Plant confidence ' : 'Show Number of Plants'}</PrimaryColorText>
      </div>
      {showPlantCounts ? getConfidenceSlider() : ''}
    </div>;
  };

  return (
    <Container className={classes.container}>
      <Card className={classes.buttonGroup}>
        {buttonGroup}
      </Card>
      <Card className={classes.datePickerCard}>
        <CardContent>
          <form className={classes.form} noValidate>
            {getUserConfig(true)?.date_range ?
            <DateRangePickerComponent
              id="date"
              label="Date"
              highlightDates={highlightDates}
              defaultStartDate={startingDate}
              defaultEndDate={startingDate}
              changeStartDate={(event) => handleChangeDateStart(event)}
              changeEndDate={(event) => handleChangeDateEnd(event)}
            /> :
            <DatePickerComponent
              id="date"
              label="Date"
              highlightDates={highlightDates}
              defaultDate={startingDate}
              changeDate={(event) => handleChangeDateStart(event)}
            />}
          </form>
        </CardContent>
      </Card>
      {getUserConfig(true)?.sort_by == 'mdc_listing' ? <><LanePositionPicker
        defaultInBedPosition={defaultInBedPosition}
        useOptions={{'lane': true, 'subLane': true}}
        options={snapshotLocations}
        onLanePositionChanged={setInBedPosition}
        setSortSnapshotsBy={setSortSnapshotsBy}
        useHooks={false}
        useDistance={false}
      />{isFilteredDataVisible() && filteredData.length > 0 && showSortBy() &&
        (<SnapshotSortByDate sortSnapshotsBy={sortSnapshotsBy} handleSortBy={handleSortBy}
          snapshotRuns={snapshotRuns} selectedRun={selectedRun} handleRunSelect={handleRunSelect}/>)}</> :
      <>
        <SimpleFilter
          triggerMethod={(v) => setFilterBatchId(v)}
          handleSubmit={handleBatchSubmit}
          label={t('Identifier')} />
        {
            getUserConfig(true)?.location_id? (<FilterByLocationId data={data}
              indexData={indexData} filteredData={filteredData}
              setFilteredData={setFilteredData} selectedDate={selectedStartDate} selectedButton={selectedButton}/>) :
               (<FilterByCrop data={data} indexData={indexData} filteredData={filteredData}
                 setFilteredData={setFilteredData} selectedDate={selectedStartDate}/>)
        }

      </>}
      {isCartVisible() ?
      <div className={classes.listButtons}>
        {getShoppingCartButton()}
        {getPlantCountCheckbox()}
      </div> : ''}
      {props.isLoading ? <Typography>{t('Collecting the data...')}</Typography> : ''}
      {getSnapshotList()}
    </Container>
  );
}

export default SnapshotList;
