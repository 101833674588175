import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {useTranslation} from 'react-i18next';
import {UserContext} from '../../contexts/userContext';
import {makeStyles} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useEffect} from 'react';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));


function HomePage() {
  const history = useHistory();
  const {t} = useTranslation();
  const classes = useStyles();
  const {getUserConfig, currentClient} = useContext(UserContext);
  const handleClick = (page) => {
    history.push(page);
  };

  useEffect(() => {
    history.push({
      pathname: `/${currentClient || 'admin'}`,
    });
  }, [currentClient]);

  const buildCardsList = () => {
    const allCards = generateAllCards();
    const allowedCards = [];
    const mappedCards = allCards.map((item) => ({'title': t(item.title), 'text': t(item.text), 'page': item.page}));
    const config = getUserConfig(null, currentClient);
    for (let i = 0; i < config?.page_access.length; i++) {
      const index = i;
      allowedCards.push(mappedCards.filter((item) => (item.title === t(config?.page_access[index])))[0]);
    }
    return allowedCards;
  };


  const generateAllCards = () => {
    const cards = [];
    const clientName = currentClient || 'admin';
    cards.push({
      'title': t('All Images'),
      'text': t('View captured images and the corresponding analysis.'),
      'page': `/${clientName}/images`,
    });
    cards.push({
      'title': t('Usage Overview'),
      'text': t('Usage Overview'),
      'page': `/${clientName}/usage-overview`,
    });
    cards.push({
      'title': t('Snapshots Page'),
      'text': t('Displays information about multiple snapshot.'),
      'page': `/${clientName}/snapshots`,
    });
    cards.push({
      'title': t('Charts'),
      'text': t('Charts message'),
      'page': `/${clientName}/charts`,
    });
    cards.push({
      'title': t('Snapshot Page'),
      'text': t('Displays information about 1 snapshot.'),
      'page': `/${clientName}/snapshots`,
    });
    cards.push({
      'title': t('Error Overview'),
      'text': t('Error Overview'),
      'page': `/${clientName}/errors`,
    });
    cards.push({
      'title': t('Comments'),
      'text': t('Shows all comments for all clients.'),
      'page': `/${clientName}/comments`,
    });
    cards.push({
      'title': t('Rerun'),
      'text': t('RerunInfo'),
      'page': `/${clientName}/rerun`,
    });
    cards.push({
      'title': t('Daily Report'),
      'text': t('Send the daily report'),
      'page': `/${clientName}/daily-report`,
    });
    cards.push({
      'title': t('Customizable charts'),
      'text': t('Customizable charts'),
      'page': `/${clientName}/charts`,
    });
    cards.push({
      'title': t('Annotations'),
      'text': t('Annotations'),
      'page': `/${clientName}/annotations`,
    });
    cards.push({
      'title': t('Upload'),
      'text': t('Upload sheet here.'),
      'page': `/${clientName}/upload-sheet`,
    });
    cards.push({
      'title': t('Batch information'),
      'text': t('To send information about upcoming batches.'),
      'page': `/${clientName}/batch-info`,
    });
    cards.push({
      'title': t('Account Page'),
      'text': t('Account information, change password.'),
      'page': `/${clientName}/account`,
    });
    cards.push({
      'title': t('System Logs'),
      'text': t('System Logs'),
      'page': `/${clientName}/system-logs`,
    });
    cards.push({
      'title': t('Dashboard Page'),
      'text': t('Dashboard Page'),
      'page': `/${clientName}/dashboard`,
    });
    cards.push({
      'title': t('Database Documents'),
      'text': t('Database Documents Page Text'),
      'page': `/${clientName}/database-documents`,
    });
    cards.push({
      'title': t('Upload Snapshot'),
      'text': t('Upload Snapshot'),
      'page': `/${clientName}/upload-snapshot`,
    });
    cards.push({
      'title': t('Delete Instances'),
      'text': t('Delete Instances'),
      'page': `/${clientName}/delete-instances`,
    });
    cards.push({
      'title': t('Export Page'),
      'text': t('Export Page'),
      'page': `/${clientName}/export-page`,
    });
    cards.push({
      'title': t('Lane Batches Page'),
      'text': t('Lane Batches Page'),
      'page': `/${clientName}/lane-batches`,
    });
    cards.push({
      'title': t('Overview Map'),
      'text': t('Overview Map'),
      'page': `/${clientName}/overview-map`,
    });
    cards.push({
      'title': t('Status Logs'),
      'text': t('Status Logs'),
      'page': `/${clientName}/status-logs`,
    });
    cards.push({
      'title': t('Export Annotations'),
      'text': t('Export Annotations'),
      'page': `/${clientName}/export-annotations`,
    });
    cards.push({
      'title': t('Batches'),
      'text': t('Batches'),
      'page': `/${clientName}/batches`,
    });
    cards.push({
      'title': t('Image Upload Page'),
      'text': t('Manually upload images'),
      'page': `/${clientName}/image-upload`,
    });
    cards.push({
      'title': t('IPM Runs Page'),
      'text': t('IPM Runs Page'),
      'page': `/${clientName}/ipm-runs`,
    });
    return cards;
  };

  return (
    <Container>
      <div className={classes.heroContent}>
        <Container>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        AgriData Innovations
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            {t('Bridging Biology & Technology')}
          </Typography>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          {buildCardsList().map((card, index) => (
            <Grid item key={card?.title} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card?.title}
                  </Typography>
                  <Typography>
                    {card?.text}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" onClick={() => handleClick(card.page)} id={`card${index}`}>
                    {t('View')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
}

export default HomePage;

