import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import 'date-fns';
import CardContent from '@material-ui/core/CardContent';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';

function FilterByCrop({indexData, filteredData, setFilteredData, selectedDate}) {
  const [cropTypes, setCropTypes] = useState(['All crops']);
  const [currentCropType, setCurrentCropType] = useState('All crops');
  const [unsortedData, setUnsortedData] = useState([]);
  const [unsortedDataSaved, setUnsortedDataSaved] = useState(false);
  const {t} = useTranslation();

  const theme = useTheme();

  useEffect(() => {
    if (filteredData.length > 0 && !unsortedDataSaved) {
      setUnsortedData(filteredData);
      setUnsortedDataSaved(true);
      extractCropTypes();
    }
  }, [filteredData]);

  useEffect(() => {
    setUnsortedDataSaved(false);
    setUnsortedData([]);
    setCropTypes(['All crops']);
    setUnsortedData([]);
  }, [selectedDate]);

  useEffect(() => {
    filterSnapshotsByCrop();
  }, [currentCropType]);

  const filterSnapshotsByCrop = () => {
    if (currentCropType === 'All crops') {
      setFilteredData(indexData(unsortedData));
    } else {
      const sortedData = unsortedData.filter((snapshot) => snapshot.data.crop === currentCropType);
      setFilteredData(indexData(sortedData));
    }
  };

  const extractCropTypes = () => {
    if (filteredData.length > 0) {
      const types = new Set(filteredData.map((snapshot) => snapshot.data.crop));
      const typesArray = Array.from(types).sort();
      setCropTypes(['All crops'].concat(typesArray));
    }
  };

  const handleCropTypeChange = (event) => {
    setCurrentCropType(event.target.value);
  };

  return (
    <Card
      style={{
        maxHeight: 500,
        overflow: 'auto',
        position: 'relative',
        marginBottom: theme.spacing(2),
      }}>
      <CardContent>
        <FormControl style={{
          marginRight: theme.spacing(1),
          width: 200,
        }}>
          <InputLabel id="crop-select-label">{t('Filter By Crop Type')}</InputLabel>
          <Select
            value={currentCropType}
            label="filter-by-crop"
            onChange={handleCropTypeChange}
            style={{marginRight: theme.spacing(1)}}
          >
            {cropTypes.length > 1 && cropTypes.map((cropType, index) =>
              <MenuItem value={cropType} key={index}>{cropType}</MenuItem>,
            )}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
}

export default FilterByCrop;
